import { AccountTransactionItem, AccountTransactionYear } from "../types/AccountTransactionYear";
import { AuditLog } from "../types/AuditLog";
import { BookSaleItem, BookSalesYear } from "../types/BookSalesYear";
import { Class, NewAdmissionClass } from "../types/Class";
import { ClassFeeYear, ClassFeeItem } from "../types/ClassFeeYear";
import { ExamFeesYear } from "../types/ExamFeesYear";
import { ExpenseItem } from "../types/ExpenseItem";
import { ExpenseType } from "../types/ExpenseType";
import { FeedingSaleItem, FeedingSaleYear } from "../types/FeedingSaleYear";
import { InventoryItem } from "../types/InventoryItem";
import { InventorySalesItem, InventorySalesYear } from "../types/InventorySalesYear";
import { InventoryType } from "../types/InventoryType";
import { LibraryBook } from "../types/LibraryBook";
import { LibraryBookRentalItem, LibraryBookRentalYear } from "../types/LibraryBookRentalYear";
import { PandCUniformSalesItem, PandCUniformSalesYear } from "../types/PandCUniformSalesYear";
import { ReportYear } from "../types/ReportYear";
import { AccountTransactionRequest } from "../types/RequestModels/AccountTransactionsRequest";
import { BookSaleItemRequest } from "../types/RequestModels/BookSaleItemRequest";
import { ClassRequest } from "../types/RequestModels/ClassRequest";
import { ExpenseItemRequest } from "../types/RequestModels/ExpenseItemRequest";
import { ExpenseTypeRequest } from "../types/RequestModels/ExpenseTypeRequest";
import { ExtraClassFeeItemRequest } from "../types/RequestModels/ExtraClassFeeItemRequest";
import { InventoryItemRequest } from "../types/RequestModels/InventoryItemRequest";
import { InventoryItemSalesRequest } from "../types/RequestModels/InventoryItemSalesRequest";
import { InventoryTypeRequest } from "../types/RequestModels/InventoryTypeRequest";
import { LibraryBookRentalRequest } from "../types/RequestModels/LibraryBookRentalRequest";
import { LibraryBookRequest } from "../types/RequestModels/LibraryBookRequest";
import { PandCUniformSalesItemRequest } from "../types/RequestModels/PandCUniformSalesItemRequest";
import { ReceiptRequest } from "../types/RequestModels/ReceiptRequest";
import { SchoolFeeArrearRequest } from "../types/RequestModels/SchoolFeeArrearRequest";
import { StudentScoreRequest } from "../types/RequestModels/StudentScoreRequest";
import { UniformSalesItemRequest } from "../types/RequestModels/UniformSalesItemRequest";
import { ScholarshipType } from "../types/ScholarshipType";
import { SchoolFeesYear, StudentTermFeeInfo } from "../types/SchoolFeesYear";
import { SchoolScoresYear } from "../types/SchoolScoresYear";
import { Student } from "../types/Student";
import { StudentScoreTermInfo } from "../types/StudentScore";
import { Subject } from "../types/Subject";
import { Teacher } from "../types/Teacher";
import { TermPayment } from "../types/TermFeePayment";
import { TermPeriod, TermYear } from "../types/TermYear";
import { UniformSalesItem, UniformSalesYear } from "../types/UniformSalesYear";

const domain = process.env.REACT_APP_API_URL; 
// const domain = "http://localhost:7071/api";

const getHeaders = () => {
   return  {
        'Content-Type': 'application/json',
    };
}

const getGETOptions = () => {
    return  {
        method: 'GET',
        headers: getHeaders()
    }
}

const getPOSTOptions = (object: any) => {
    return  {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(object)
    }
}

export const getTestFunction = async () => {
    const response = await fetch(`${domain}/TestFunction`, getGETOptions())
        .then(response => response.json() as Promise<string>);

    return response;
}

export const getGenerateSpreadsheet = async () => {
    const response = await fetch(`${domain}/GenerateSpreadsheet`, getGETOptions())
        .then(response => response.json() as Promise<string>);

    return response;
}


// Class Fee Items
export const getClassFees = async () => {
    const response = await fetch(`${domain}/GetClassFees`, getGETOptions())
        .then(response => response.json() as Promise<ClassFeeYear[]>);

    return response;
}

export const getClassFee = async (id: string) => {
    const response = await fetch(`${domain}/GetClassFee/${id}`, getGETOptions())
    .then(response => response.json() as Promise<ClassFeeItem>);

    return response;
}

export const postClassFee = async (classFee: ExtraClassFeeItemRequest) => {
    const response = await fetch(`${domain}/PostClassFee`, getPOSTOptions(classFee));

    return response;
};

// Class 
export const getClasses = async () => {
    const response = await fetch(`${domain}/GetClasses`, getGETOptions())
        .then(response => response.json() as Promise<Class[]>);

    return response;
}

export const getClass = async (id: string) => {

    const response = await fetch(`${domain}/GetClass/${id}`, getGETOptions())
        .then(response => response.json() as Promise<Class>);

    return response;
}

export const postClass = async (classItem:ClassRequest) => {
    const response = await fetch(`${domain}/PostClass`, getPOSTOptions(classItem));

    return response;
}

export const getNewAdmissions = async () => {

    const response = await fetch(`${domain}/GetNewAdmissions`, getGETOptions())
        .then(response => response.json() as Promise<NewAdmissionClass[]>);

    return response;
}

// Student
export const getStudents = async () => {
    const response = await fetch(`${domain}/GetStudents`, getGETOptions())
        .then(response => response.json() as Promise<Student[]>);

    return response;
}

export const getStudent = async (id: string) => {
    
    const response = await fetch(`${domain}/GetStudent/${id}`, getGETOptions())
        .then(response => response.json() as Promise<Student>);

    return response;
}

export const getStudentTermFeeInfo = async (termId: string, studentId: string) => {
    
    const response = await fetch(`${domain}/GetStudentTermFeeInfo/${termId}/${studentId}`, getGETOptions())
        .then(response => response.json() as Promise<StudentTermFeeInfo>);

    return response;
}

export const postStudent = async (student: Student) => {
    const response = await fetch(`${domain}/PostStudent`, getPOSTOptions(student));

    return response;
}

export const getStudenstByClassId = async (classId: string) => {
    const response = await fetch(`${domain}/GetClassStudents/${classId}`, getGETOptions())
        .then(response => response.json() as Promise<Student[]>);

    return response;

}

export const updateMultipleStudents = async (students:Student[]) => {
    const response = await fetch(`${domain}/UpdateMultipleStudents`, getPOSTOptions(students));

    return response;
}


// Scholarship Types
export const getScholarshipTypes = async () => {
    const response = await fetch(`${domain}/GetScholarshipTypes`, getGETOptions())
        .then(response => response.json() as Promise<ScholarshipType[]>);

    return response;
}

export const getScholarshipType = async (id: string) => {
    const response = await fetch(`${domain}/GetScholarshipType/${id}`, getGETOptions())
        .then(response => response.json() as Promise<ScholarshipType>);

    return response;
}

export const postScholarshipType = async (scholarshipType: ScholarshipType) => {
    const response = await fetch(`${domain}/PostScholarshipType`, getPOSTOptions(scholarshipType));

    return response;
}

// Staff
export const getStaff = async () => {
    const response = await fetch(`${domain}/GetTeachers`, getGETOptions())
        .then(response => response.json() as Promise<Teacher[]>);

    return response;
}

export const getStaffMember = async (id: string) => {
    const response = await fetch(`${domain}/GetTeacher/${id}`, getGETOptions())
        .then(response => response.json() as Promise<Teacher>);

    return response;
}

export const postStaff = async (teacher: Teacher) => {
    const response = await fetch(`${domain}/PostTeacher`, getPOSTOptions(teacher));

    return response;
}

export const deleteStaffMember = async (id: string) => {
    const response = await fetch(`${domain}/DeleteTeacher`, getPOSTOptions(id));

    return response;
}

// Account Transactions
export const getAccountTransactions = async () => {
    const response = await fetch(`${domain}/GetAccountTransactions`, getGETOptions())
        .then(response => response.json() as Promise<AccountTransactionYear[]>);

    return response;
}

export const getAccountTransaction = async (id: string) => {
    const response = await fetch(`${domain}/GetAccountTransaction/${id}`, getGETOptions())
        .then(response => response.json() as Promise<AccountTransactionItem>);

    return response;
}

export const getAccountTransactionTypes = async () => {

    const response = await fetch(`${domain}/GetAccountTransactionTypes`, getGETOptions())
        .then(response => response.json() as Promise<string[]>);

    return response;
}


export const getReceipts = async (trasactionType: string, transactionDate: Date) => {

    var receiptRequest: ReceiptRequest = {
        type: trasactionType,
        date: transactionDate
    };

    const response = await fetch(`${domain}/GetReceipts`, getPOSTOptions(receiptRequest))
        .then(response => response.json() as Promise<string[]>);

    return response;
}

export const deleteReceipt = async (receipt: string) => {

    const response = await fetch(`${domain}/DeleteReceipt`, getPOSTOptions(receipt))
        .then(response => response.json() as Promise<string>);

    return response;
}

export const postAccountTransaction = async (accountTransaction:AccountTransactionRequest) => {

    const response = await fetch(`${domain}/PostAccountTransaction`, getPOSTOptions(accountTransaction));

    return response;
}

// Inventory Types
export const getInventoryTypes = async () => {
    const response = await fetch(`${domain}/GetInventoryItemTypes`, getGETOptions())
        .then(response => response.json() as Promise<InventoryType[]>);

    return response;
}

export const getInventoryType = async (id: string) => {
    const response = await fetch(`${domain}/GetInventoryItemType/${id}`, getGETOptions())
        .then(response => response.json() as Promise<InventoryType>);

    return response;
}

export const postInventoryType = async (inventoryType: InventoryTypeRequest) => {
    const response = await fetch(`${domain}/PostInventoryItemType`, getPOSTOptions(inventoryType));

    return response;
}

export const deleteInventoryType = async (id: string) => {
    const response = await fetch(`${domain}/DeleteInventoryItemType`, getPOSTOptions(id));

    return response;
}

// Inventory 
export const getInventory = async () => {
    const response = await fetch(`${domain}/GetInventoryItems`, getGETOptions())
        .then(response => response.json() as Promise<InventoryItem[]>);

    return response;
}

export const getInventoryItem = async (id: string) => {

    const response = await fetch(`${domain}/GetInventoryItem/${id}`, getGETOptions())
        .then(response => response.json() as Promise<InventoryItem>);

    return response;
}

export const getInventoryItemsByTypeId = async (id: string) => {

    const response = await fetch(`${domain}/GetInventoryItems`, getGETOptions())
        .then(response => response.json() as Promise<InventoryItem[]>);

    return response.filter(t => t.inventoryTypeId === id);
}

export const postInventoryItem = async (inventoryItem: InventoryItemRequest) => {
    const response = await fetch(`${domain}/PostInventoryItem`, getPOSTOptions(inventoryItem));

    return response;
}

export const postInventoryItemSale = async (inventoryItemSale: InventoryItemSalesRequest) => {
    const response = await fetch(`${domain}/PostInventoryItemSale`, getPOSTOptions(inventoryItemSale));

    return response;
}

export const getInventorySalesSales = async () => {
    const response = await fetch(`${domain}/GetInventoryItemsSales`, getGETOptions())
        .then(response => response.json() as Promise<InventorySalesYear[]>);

    return response;
}

export const getInventorySaleItem = async (id: string) => {
    const response = await fetch(`${domain}/GetInventoryItemsSale/${id}`, getGETOptions())
        .then(response => response.json() as Promise<InventorySalesItem>);

    return response;
}

// Expenses Types
export const getExpenseTypes = async () => {
    const response = await fetch(`${domain}/GetExpenseTypes`, getGETOptions())
        .then(response => response.json() as Promise<ExpenseType[]>);

    return response;
}

export const getExpenseType = async (id: string) => {
    const response = await fetch(`${domain}/GetExpenseType/${id}`, getGETOptions())
        .then(response => response.json() as Promise<ExpenseType>);
    return response;
}

export const postExpenseType = async (expenseType: ExpenseTypeRequest) => {
    const response = await fetch(`${domain}/PostExpenseType`, getPOSTOptions(expenseType));

    return response;
}

// Expenses
export const getExpenses = async () => {
    const response = await fetch(`${domain}/GetExpenseItems`, getGETOptions())
        .then(response => response.json() as Promise<ExpenseItem[]>);

    return response;
}

export const getExpense = async (id: string) => {
    const response = await fetch(`${domain}/GetExpenseItem/${id}`, getGETOptions())
        .then(response => response.json() as Promise<ExpenseItem>);

    return response;
}

export const postExpense = async (expense: ExpenseItemRequest) => {

    const response = await fetch(`${domain}/PostExpenseItem`, getPOSTOptions(expense));

    return response;
}

// Book Sales
export const getBookSales = async () => {
    const response = await fetch(`${domain}/GetBookSales`, getGETOptions())
        .then(response => response.json() as Promise<BookSalesYear[]>);

    return response;
}

export const getBookSale = async (id: string) => {
    const response = await fetch(`${domain}/GetBookSale/${id}`, getGETOptions())
        .then(response => response.json() as Promise<BookSaleItem>);

    return response;
}

export const getBookItemTypes = async () => {
    const response = await fetch(`${domain}/GetBookItemTypes`, getGETOptions())
        .then(response => response.json() as Promise<InventoryItem[]>);

    return response;
}

export const postBookSale = async (bookSale: BookSaleItemRequest) => {
    const response = await fetch(`${domain}/PostBookSale`,  getPOSTOptions(bookSale));

    return response;
}

// Uniform Sales
export const getUniformSales = async () => {
    const response = await fetch(`${domain}/GetUniforms`, getGETOptions())
        .then(response => response.json() as Promise<UniformSalesYear[]>);

    return response;
}

export const getUniformSale = async (id: string) => {
    const response = await fetch(`${domain}/GetUniform/${id}`, getGETOptions())
        .then(response => response.json() as Promise<UniformSalesItem>);

    return response;
}

export const getUniformItemTypes = async () => {
    const response = await fetch(`${domain}/GetUniformItemTypes`, getGETOptions())
        .then(response => response.json() as Promise<InventoryItem[]>);

    return response;
}

export const postUniformSale = async (uniformSale: UniformSalesItemRequest) => {
    const response = await fetch(`${domain}/PostUniform`, getPOSTOptions(uniformSale));

    return response;
}


// Uniform Sales
export const getPandCUniformSales = async () => {
    const response = await fetch(`${domain}/GetPandCUniforms`, getGETOptions())
        .then(response => response.json() as Promise<PandCUniformSalesYear[]>);

    return response;
}

export const getPandCUniformSale = async (id: string) => {
    const response = await fetch(`${domain}/GetPandCUniform/${id}`, getGETOptions())
        .then(response => response.json() as Promise<PandCUniformSalesItem>);

    return response;
}

export const postPandCUniformSale = async (uniformSale: PandCUniformSalesItemRequest) => {
    const response = await fetch(`${domain}/PostPandCUniform`, getPOSTOptions(uniformSale));

    return response;
}

// Feeding Items 
export const getFeedingItems = async () => {
    const response = await fetch(`${domain}/GetFeedingItems`, getGETOptions())
        .then(response => response.json() as Promise<FeedingSaleYear[]>);

    return response;
}

export const getFeedingItem = async (id: string) => {
    const response = await fetch(`${domain}/GetFeedingItem/${id}`, getGETOptions())
        .then(response => response.json() as Promise<FeedingSaleItem>);

    return response;
}

export const postFeedingItem = async (feedingItem: FeedingSaleItem) => {
    const response = await fetch(`${domain}/PostFeedingItem`, getPOSTOptions(feedingItem));

    return response;
}

// Library Book
export const getLibraryBooks = async () => {
    const response = await fetch(`${domain}/GetLibraryBooks`, getGETOptions())
        .then(response => response.json() as Promise<LibraryBook[]>);

    return response;
}

export const getLibraryBook = async (id: string) => {
    const response = await fetch(`${domain}/GetLibraryBook/${id}`, getGETOptions())
        .then(response => response.json() as Promise<LibraryBook>);

    return response;
}

export const postLibraryBook = async (libraryBook: LibraryBookRequest) => {
    const response = await fetch(`${domain}/PostLibraryBook`, getPOSTOptions(libraryBook));

    return response;
}

// Libray Book Rental 
export const getLibraryBookRentals = async () => {
    const response = await fetch(`${domain}/GetLibraryBookRentals`, getGETOptions())
        .then(response => response.json() as Promise<LibraryBookRentalYear[]>);

    return response;
}

export const getLibraryBookRental = async (id: string) => {
    const response = await fetch(`${domain}/GetLibraryBookRental/${id}`, getGETOptions())
        .then(response => response.json() as Promise<LibraryBookRentalItem>);

    return response;
}

export const postLibraryBookRental = async (libraryBookRental: LibraryBookRentalRequest) => {

    const response = await fetch(`${domain}/PostLibraryBookRental`, getPOSTOptions(libraryBookRental));

    return response;
}

export const deleteLibraryRental = async (id: string) => {
    const response = await fetch(`${domain}/DeleteBookRental`, getPOSTOptions(id));

    return response;
}

// Term Years 
export const getTermYears = async () => {
    const response = await fetch(`${domain}/GetTermYears`, getGETOptions())
        .then(response => response.json() as Promise<TermYear[]>);

    return response;
}

export const getTermYear = async (id: string) => {
    const response = await fetch(`${domain}/GetTermYear/${id}`, getGETOptions())
        .then(response => response.json() as Promise<TermYear>);

    return response;
}

export const postTermYear = async (termYear: TermYear) => {
    const response = await fetch(`${domain}/PostTermYear`, getPOSTOptions(termYear));

    return response;
}

export const getTermPeriod = async (id: string) => {
    const response = await fetch(`${domain}/GetTermPeriod/${id}`, getGETOptions())
        .then(response => response.json() as Promise<TermPeriod>);

    return response;
}

// School Fees
export const getSchoolFees = async () => {
    const response = await fetch(`${domain}/GetAllSchoolFees`, getGETOptions())
        .then(response => response.json() as Promise<SchoolFeesYear[]>);

    return response;
}

export const getSchoolFeeTermPayments = async (termId: string, studentId: string) => {

    const response = await fetch(`${domain}/GetSchoolFeeTermPayments/${termId}/${studentId}`, getGETOptions())
        .then(response => response.json() as Promise<TermPayment[]>);

    return response;
}

export const postTermFeePayment = async (termFeePayment: TermPayment) => {
    const response = await fetch(`${domain}/PostTermFeePayment`, getPOSTOptions(termFeePayment));

    return response;
} 

export const getSchoolFeeArrear = async (termId: string, studentId: string) => {

    const response = await fetch(`${domain}/GetSchoolFeeArrear/${termId}/${studentId}`, getGETOptions())
        .then(response => response.json() as Promise<SchoolFeeArrearRequest>);

    return response;
}

export const postSchoolFeeArrear = async (schoolFeeArrear: SchoolFeeArrearRequest) => {
    const response = await fetch(`${domain}/PostSchoolFeeArrear`, getPOSTOptions(schoolFeeArrear));

    return response;
} 

export const getStudentScores = async () => {
    const response = await fetch(`${domain}/GetAllStudentScores`, getGETOptions())
        .then(response => response.json() as Promise<SchoolScoresYear[]>);;

    return response;
} 

export const getStudentScoreTermInfo = async (termId: string, studentId: string) => {

    const response = await fetch(`${domain}/GetStudentScoreTermInfo/${termId}/${studentId}`, getGETOptions())
        .then(response => response.json() as Promise<StudentScoreTermInfo>);

    return response;
}


export const postStudentScore = async (studentScoreRequest: StudentScoreRequest) => {
    const response = await fetch(`${domain}/PostStudentScore`, getPOSTOptions(studentScoreRequest));

    return response;
} 

// Exam Fees
export const getExamFees = async () => {
    const response = await fetch(`${domain}/GetAllExamFees`, getGETOptions())
        .then(response => response.json() as Promise<ExamFeesYear[]>);

    return response;
}

export const getExamFeeTermPayments = async (termId: string, studentId: string) => {

    const response = await fetch(`${domain}/GetExamFeeTermPayments/${termId}/${studentId}`, getGETOptions())
        .then(response => response.json() as Promise<TermPayment[]>);

    return response;
}

export const postExamFeePayment = async (termFeePayment: TermPayment) => {
    const response = await fetch(`${domain}/PostExamFeePayment`, getPOSTOptions(termFeePayment));

    return response;
}  


// Subjects
export const getSubjects = async () => {
    const response = await fetch(`${domain}/GetSubjects`, getGETOptions())
        .then(response => response.json() as Promise<Subject[]>);

    return response;
}

export const getSubject = async (subjectId: string) => {

    const response = await fetch(`${domain}/GetSubject/${subjectId}`, getGETOptions())
        .then(response => response.json() as Promise<Subject>);

    return response;
}

export const postSubject = async (subject: Subject) => {
    const response = await fetch(`${domain}/PostSubject`, getPOSTOptions(subject));

    return response;
}  

export const deleteGrade = async (id: string) => {
    const response = await fetch(`${domain}/DeleteGrade`, getPOSTOptions(id));

    return response;
}

export const deleteSubject = async (id: string) => {
    const response = await fetch(`${domain}/DeleteSubject`, getPOSTOptions(id));

    return response;
}

// System Users
export const getSystemPrivileges = async () => {

    const response = await fetch(`${domain}/GetSystemPrivilegeLevels`, getGETOptions())
        .then(response => response.json() as Promise<string[]>);

    return response;
}

// Audit Logs
export const getAuditLogs = async () => {

    const response = await fetch(`${domain}/GetAuditLogs`, getGETOptions())
        .then(response => response.json() as Promise<AuditLog[]>);

    return response;
}

// Report
export const getReport = async () => {
    const response = await fetch(`${domain}/GetReport`, getGETOptions())
        .then(response => response.json() as Promise<ReportYear[]>);

    return response;
}
// Environment Variable
export const getEnvironmentVariable = async (id: string) => {
    const response = await fetch(`${domain}/GetEnvironmentVariable/${id}`, getGETOptions())
        .then(response => response.json() as Promise<string>);

    return response;
}

// Health Check
export const getHealthCheck = async () => {
    const response = await fetch(`${domain}/HealthCheck`, getGETOptions())
        .then(response => response.json() as Promise<string[]>);

    return response;
}
