import { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getSubjects } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';
import { Subject } from '../../types/Subject';

function Subjects() {
    
    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  const [subjects, setSubjects] = useState<Subject[]>([]);

  useEffect(() => {
    // fetch data
    getSubjects().then((data) => {
        setSubjects(data);
    }
    )

  }, [])

  return (
    <>
        {
            canEdit(systemUser) &&
            <div className='add-new-class-btn'>
                <Link className="navitem" to="/Subject/new">
                    <Button variant="primary" className="mb-3">
                        Add Subject
                    </Button>
                </Link>
            </div>
        }

      {subjects ?
        subjects.length > 0 ?
        (
            <div className='page-content'>
                <Table striped hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjects.map((item: Subject) => (
                            <tr id={createRowTestId(item.name)} className='year-row' key={item.id}>
                                <td className='subject-name'>{item.name}</td>
                                <td>
                                   {
                                    canEdit(systemUser) &&
                                    <Link to={`/Subject/${item.id}`} className="button">
                                        <Button id={`${createButtonTestId(item.id)}`}>
                                            Edit
                                        </Button>
                                    </Link>
                                   }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        ) : <p>No subjects found</p>
      : <Loading />}
    </>
  )
}

export default Subjects;