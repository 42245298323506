import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getEnvironmentVariable, getInventoryItem, getInventoryTypes, postInventoryItem } from '../../functions/fetchEntities';
import { InventoryType } from '../../types/InventoryType';
import Loading from '../Loading';
import { InventoryItemRequest } from '../../types/RequestModels/InventoryItemRequest';
import { InventoryItemMapper } from '../../mappers/InventoryItemMapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import ReceiptComponent from '../ReceiptComponent';
import { IsSuperUser } from '../../helpers/UserHelper';

function EditInventory() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [selectedInventory, setInventory] = useState({} as InventoryItemRequest);
const [inventoryTypes, setInventoryTypes] = useState<InventoryType[]>([]);
const [uniformTypeID, setUniformTypeId] = useState<string>("");

const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getInventoryItem(parsedId)
            .then((data) => setInventory(InventoryItemMapper.mapInventoryItemToInventoryItemRequest(data)));
    }

    getInventoryTypes().then((data) => {
        setInventoryTypes(data);
      }
    )

    getEnvironmentVariable("UNIFORM_TYPE_ID").then((data) => setUniformTypeId(data));
        
    }, [parsedId]);

 
    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setInventory({...selectedInventory, name: name});
        setHasBeenEdited(true);
    }

    const handleInventoryTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const inventoryTypeId = event.target.value;
        setInventory({...selectedInventory, inventoryTypeId: inventoryTypeId});
        setHasBeenEdited(true);
    }

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        const quantity = parseFloat(event.target.value);
        setInventory({...selectedInventory, quantity: quantity});
        setHasBeenEdited(true);
    }

    const handleCostPerUnitChange = (event: ChangeEvent<HTMLInputElement>) => {
        const costPerUnit = parseFloat(event.target.value);
        setInventory({...selectedInventory, costPerUnit: costPerUnit});
        setHasBeenEdited(true);
    }

    const handleSellingPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const sellingPrice = parseFloat(event.target.value);
        setInventory({...selectedInventory, sellingPrice: sellingPrice});
        setHasBeenEdited(true);
    }

    const handleIsSelelableChange = () => {

        var isSellable = !selectedInventory.isSellable;

        setInventory({...selectedInventory, isSellable: isSellable});
        setHasBeenEdited(true);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
    
        if(files){

            // const file = getfile(files);
            // setInventory({...selectedInventory, image: file});

            let reader = new FileReader(); 
            reader.readAsDataURL(files[0]);
    
            reader.onload = () => {

                setInventory({...selectedInventory, image: reader.result});
                setHasBeenEdited(true);
            }
        }
    }


    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                selectedInventory.updatedBy = systemUser.displayName;
                await postInventoryItem(selectedInventory);
                navigate('/Inventory', {replace: true});
            }
        }
    
        setValidated(true);
      };

  
    return (
        <>
            {parsedId === "new" || selectedInventory.id ?
            <div>
                <h1>Edit Inventory: {selectedInventory.inventoryType?.name}</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                        id="edit-name"
                        type="text" 
                        placeholder="Inventory Type Name" 
                        onChange={handleNameChange} 
                        value={selectedInventory.name} 
                        required
                        />
                    </Form.Group>

                    {
                        !selectedInventory.inventoryType ? <Form.Group className="mb-3" controlId="formInventoryType">
                        <Form.Label>Type</Form.Label>
                        <select className="form-select" aria-label="Class" onChange={handleInventoryTypeChange} required>
                            <option value="">Select a type</option>
                            {inventoryTypes.map((inventoryType) => (
                                <option key={inventoryType.id} value={inventoryType.id} selected={selectedInventory.inventoryTypeId === inventoryType.id}>{inventoryType.name}</option>
                            ))}
                        </select>
                    </Form.Group> : null
                    }

                    <Form.Group className="mb-3" controlId="formQuantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                        id="edit-quantity"
                        type="number"
                        step="any"
                        placeholder="Quantity"
                        onChange={handleQuantityChange}
                        value={selectedInventory.quantity}
                        min="0"
                        required
                        />
                    </Form.Group>

                    {
                        IsSuperUser(systemUser) && <Form.Group className="mb-3" controlId="formCostPerUnit">
                        <Form.Label>Cost Per Unit</Form.Label>
                        <Form.Control
                        type="number"
                        step="any"
                        placeholder="Cost Per Unit"
                        onChange={handleCostPerUnitChange}
                        value={selectedInventory.costPerUnit}
                        min="0.1"
                        required
                        />
                    </Form.Group>
                    }

                    {
                        selectedInventory.inventoryTypeId !== uniformTypeID
                        && 
                        <Form.Group className="mb-3" controlId="formSellingPrice">
                            <Form.Label>Selling Price</Form.Label>
                            <Form.Control
                            id="edit-selling-price"
                            type="number"
                            step="any"
                            placeholder="Selling Price"
                            onChange={handleSellingPriceChange}
                            value={selectedInventory.sellingPrice}
                            min="0.1"
                            required
                            />
                        </Form.Group>
                    }

                    {
                        selectedInventory.inventoryTypeId !== uniformTypeID
                        && 
                        <td>
                            <Form.Group className="mb-3" controlId="formForSale">
                                <Form.Label>For Sale</Form.Label>
                                <Form.Check type="switch" checked={selectedInventory.isSellable} onClick={handleIsSelelableChange} />
                            </Form.Group>
                        </td>
                    }

                        {
                            (selectedInventory.image === null || selectedInventory.image === undefined) &&
                            <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                                <Form.Control 
                                type="file"
                                onChange={handleFileChange} 
                                accept='image/*'
                                />
                            </Form.Group>
                        }

                    <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                        Save
                    </Button>
                    </Form>
                </div>
                </div>

                <ReceiptComponent receipt={selectedInventory.image?.toString()} returnUrl='/Inventory' />

            </div> : <Loading />}
        </>
  )
}

export default EditInventory;