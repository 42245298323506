import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Class } from '../../types/Class';
import { Student } from '../../types/Student';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function SchoolGradesTable(data: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const classes = data.data.items as Class[];
    const termId  = data.data.id;

    const getThisTermsScores = (student: Student) => {

        const thisTermScores = student.studentScores.filter(p => p.termId === termId);
        // const totalPaid = thisTermPayments.reduce((total, payment) => total + payment.amountPaid, 0);

        return thisTermScores;
    }

  return (
    <Accordion>
          {classes.map((item: Class) => (
              <Accordion.Item key={item.id} eventKey={item.name}>
                  <Accordion.Header>{item.name}</Accordion.Header>
                  <Accordion.Body>
                    {
                    item.students.length > 0 ? <Table striped hover responsive>
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Scores</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          {item.students.map((student: Student) => {

                            const termScores = getThisTermsScores(student);

                            return (
                                <tr key={student.id} className={`${termScores.length > 0 ? "success" : "warning"}`}>
                                  <td>{student.name}</td>
                                  <td>
                                      {
                                        termScores.map(ts => {
                                            return(
                                                <p key={ts.subjectId}>{ts.subject.name} - {ts.scoreAchieved}</p>
                                            )
                                        })
                                      }
                                  </td>
                                  <td>
                                      {
                                        canEdit(systemUser) &&
                                        <Link to={`/StudentGrades/${termId}/${student.id}`} className="button">
                                            <Button>
                                                Edit
                                            </Button>
                                        </Link>
                                      }
                                  </td>
                              </tr>
                              )})}
                      </tbody>
                    </Table> : <div>No termfees to show</div>}
                  </Accordion.Body>
              </Accordion.Item>
              ))}
        </Accordion>
  )
}

export default SchoolGradesTable;