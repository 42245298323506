import { useEffect, useState } from 'react'
import { Accordion, Button, Table } from 'react-bootstrap';
import { getAuditLogs, getGenerateSpreadsheet } from '../../functions/fetchEntities';
import { AuditLog, GroupedAuditLogs } from '../../types/AuditLog';
import Loading from '../Loading';
import { getDisplayDate } from '../../helpers/DateHelper';

function AuditLogs() {

  const [auditLogs, setAuditLogs] = useState([] as AuditLog[]);

  const generateAuditLogRow = (item: AuditLog) => {

    const changes = item.changes.split(";");

    return (
      <tr key={item.id} >
        <td>{item.type}</td>
        <td>
          <ul>
            {changes.map((change: string, index: number) => {

              return (
                <li key={`${index}`}>
                  {change}
                </li>
              )
            })}
          </ul>
        </td>
        <td>{item.updatedBy}</td>
    </tr>
  )

  }

  const groupAuditLogsByDate = (auditLogs: AuditLog[]): GroupedAuditLogs[] => {

    const groupedLogs: { [date: string]: AuditLog[] } = {};

    auditLogs.forEach(log => {
        const date = getDisplayDate(log.date);
        
        if (!groupedLogs[date]) {
            groupedLogs[date] = [];
        }
        
        groupedLogs[date].push(log);
    });

    return Object.keys(groupedLogs).map(date => ({
        date,
        logs: groupedLogs[date]
    }));
  }

  const generateSpreadsheet = async () => {
    
    getGenerateSpreadsheet();
    
    alert("https://drive.google.com/drive/folders/1GJmsoobHQQL17qdZPMRz4bbB_rzPzxy7");
  }


  const groupedLogs = groupAuditLogsByDate(auditLogs);


  useEffect(() => {

    const loadPage = async () =>  {
      const data = await getAuditLogs();
      setAuditLogs(data);
    }

    loadPage();

  // eslint-disable-next-line
  }, []);


  return (
    <>
        {auditLogs ?
      auditLogs.length > 0 ?
      <div>
        <div className='centered'>
            <br />
            <Button variant="primary" className="mb-3" onClick={generateSpreadsheet}>
                  Generate Spreadsheet
              </Button>
          </div>

        <Accordion>
          {
            groupedLogs.map(gl => {

              return (
                <Accordion.Item eventKey={gl.date}>
                  <Accordion.Header>
                    {gl.date}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Table striped hover responsive>
                      <thead>
                          <tr>
                              <th>Type</th>
                              <th>Changes</th>
                              <th>Updated By</th>
                          </tr>
                      </thead>
                      <tbody>
                          {gl.logs.map((item: AuditLog) => generateAuditLogRow(item))}
                      </tbody>
                    </Table>
                  </Accordion.Body>

                </Accordion.Item>
              )
            })
          }
        </Accordion>
        
          {/*   */}
      </div>
      : <p>No audit logs found</p>
      : <Loading />}
    </>

  )
}

export default AuditLogs;