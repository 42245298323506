import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getDisplayDate } from '../../helpers/DateHelper';
import { createButtonTestId } from '../../helpers/StringHelper';
import { InventoryType } from '../../types/InventoryType';
import { InventorySalesItem } from '../../types/InventorySalesYear';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit, IsSuperUser } from '../../helpers/UserHelper';

function InventoryTable(props: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const sales = props.data as InventorySalesItem[];
    const inventoryTypes = props.inventoryTypes as InventoryType[];

  return (
    <>
        <div>
            <Accordion defaultActiveKey={inventoryTypes.map(u => u.name)} alwaysOpen>
            {inventoryTypes.map((inventoryItemType: InventoryType) => {
                
                const inventorySales = sales.filter(s => s.inventoryItem.inventoryTypeId === inventoryItemType.id);
                
                return (
                    <Accordion.Item eventKey={inventoryItemType.name}>
                        <Accordion.Header className='inventory-table-row'>{inventoryItemType.name}</Accordion.Header>
                        <Accordion.Body>
                            {inventorySales.length > 0 ?
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Student</th>
                                            <th>Class</th>
                                            <th>Item</th>
                                            <th>Quantity</th>
                                            {IsSuperUser(systemUser) ? <th>Revenue</th> : null}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {inventorySales.map((item: InventorySalesItem) => (
                                        <tr key={item.id}>
                                            <td>{getDisplayDate(item.date)}</td>
                                            <td>{item.student.name}</td>
                                            <td>{item.student.class?.name}</td>
                                            <td>{item.inventoryItem.name}</td>
                                            <td>{item.quantity}</td>
                                            {IsSuperUser(systemUser) ? <td>{item.revenue}</td> : null}
                                            <td>
                                               {
                                                canEdit(systemUser) &&
                                                <Link to={`/InventorySale/${item.id}`} className="button">
                                                    <Button id={createButtonTestId(`${item.student.name}_${item.inventoryItem.name}_${getDisplayDate(item.date)}`)}>
                                                        Edit
                                                    </Button>
                                                </Link>
                                               }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            : (
                                <tr className='no-class-row'>
                                    <td colSpan={6}>No inventory sales</td>
                                </tr>
                            )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                )
            })}
            </Accordion>
        </div>
    </>
  )
}

export default InventoryTable;