import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteSubject, getSubject, postSubject } from '../../functions/fetchEntities';
import Loading from '../Loading';
import { Subject } from '../../types/Subject';
import { IsSuperUser } from '../../helpers/UserHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

function EditSubject() {

  const state = useSelector((state: RootState) => state.systemUser);
  const systemUser = state.systemUser;

const [validated, setValidated] = useState(false);
const [subject, setSubject] = useState({id: "", name: ""} as Subject);
const navigate = useNavigate();

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  useEffect(() => {

    if (parsedId !== "new") {
        getSubject(parsedId)
            .then((data) => setSubject(data));
    }
        
    }, [parsedId]);


    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value;
      setSubject({...subject, name: name});
  }


  const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${subject.name}`)) {

        await deleteSubject(subject.id);
  
        alert("Subject deleted");
        navigate('/Subjects', {replace: true});
      }
}

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            await postSubject(subject);
            navigate('/Subjects', {replace: true});
        }
    
        setValidated(true);
      };

    return (
        <>
             {parsedId === "new" || subject.id !== undefined ?
             <div>
             <h1>Edit Subject</h1>
     
             <div className='form-background'>
             <div className='form-container'>
                 <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                 <Form.Group className="mb-3" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                        id="edit-name"
                        type="text" 
                        placeholder="Subject Name" 
                        onChange={handleNameChange} 
                        value={subject.name} 
                        required
                        />
                    </Form.Group>

                 <Button id='save' className='edit-form-submit' variant="primary" type="submit">
                     Save
                 </Button>

                 {
                      (parsedId !== "new" && IsSuperUser(systemUser)) && (
                          <Button className='edit-form-submit' variant="danger" onClick={handleDelete} >
                              Delete
                          </Button>
                      )
                  }
                 </Form>
             </div>
             </div>
          </div> : <Loading />}
        </>
  )
}

export default EditSubject;