import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getSchoolFeeTermPayments, postTermFeePayment } from '../../functions/fetchEntities';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { TermPaymentRequest } from '../../types/RequestModels/TermFeePaymentRequest';
import { TermFeePaymentMapper } from '../../mappers/TermFeePaymentMapper';
import StudentTermFeeInfoComponent from './StudentTermFeeInfoComponent';
import ReceiptComponent from '../ReceiptComponent';


function EditSchoolFeePage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const { termId, studentId, paymentId } = useParams();
const parsedTermId = termId !== undefined ? termId : "";
const parsedStudentId = studentId !== undefined ? studentId : "";
const parsedPaymentId = paymentId !== undefined || paymentId !== "new" ? paymentId : "";

//TODO: make sure IDs is not null
const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [termFeePayment, setTermFeePayment] = useState({
    id: "",
    paymentDate: new Date(),
    termId: parsedTermId,
    studentId: parsedStudentId,
    amountPaid: 0,
}  as TermPaymentRequest);


const navigate = useNavigate();

const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    setTermFeePayment({...termFeePayment, paymentDate: date});
    setHasBeenEdited(true);
}

const handleAmountPaidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amountPaid = parseFloat(event.target.value);
    setTermFeePayment({...termFeePayment, amountPaid: amountPaid});
    setHasBeenEdited(true);
}

const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const notes = event.target.value;
    setTermFeePayment({...termFeePayment, notes: notes});
    setHasBeenEdited(true);
}

const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;
    if(file){
        let reader = new FileReader(); 
        reader.readAsDataURL(file[0]);
        reader.onload = () => {
            setTermFeePayment({...termFeePayment, receipt: reader.result});
            setHasBeenEdited(true);
        }
    }
}


const handleSubmit = async (event:any) => {

    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        if(hasBeenEdited){
            termFeePayment.updatedBy = systemUser.displayName;
            postTermFeePayment(termFeePayment)
                .then(() => navigate(`/SchoolFee/${parsedTermId}/${parsedStudentId}`));
        }
    }

    setValidated(true);
  };

  
  useEffect(() => {

    const loadPage = async () => {

    const termFeePayments = (await getSchoolFeeTermPayments(parsedTermId, parsedStudentId)).map((item: any) => TermFeePaymentMapper.mapTermFeePaymentToTermFeePaymentRequest(item));

    const payment = termFeePayments.find((item: TermPaymentRequest) => item.id === parsedPaymentId);  
    
    if(payment !== undefined){
        setTermFeePayment(TermFeePaymentMapper.mapTermFeePaymentToTermFeePaymentRequest(payment));
    }

    }

     loadPage(); 
    }, [parsedPaymentId, parsedStudentId, parsedTermId]);

    

  
    return (
        <>
            {
                <div>
                    <StudentTermFeeInfoComponent termId={parsedTermId} studentId={parsedStudentId} editMode={true} /> 

                    <div className='form-background'>
                        <div className='form-container'>
                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)} >
                                <Form.Group className="mb-3" controlId="formDate">
                                    <Form.Label>Date Paid</Form.Label>
                                    <Form.Control 
                                    type="date" 
                                    placeholder="Date" 
                                    value={getCalendarDate(termFeePayment.paymentDate)} 
                                    onChange={handleDateChange} 
                                    onKeyDown={(e) => disableTyping(e)}
                                    min={minDate}
                                    max={maxDate}
                                    required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPaid">
                                    <Form.Label>Amount Paid</Form.Label>
                                    <Form.Control 
                                    type="number"
                                    step="any" 
                                    placeholder="Amount Paid" 
                                    onChange={handleAmountPaidChange} 
                                    value={termFeePayment.amountPaid} 
                                    min="0.1"
                                    required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Notes</Form.Label>
                                    <textarea id="edit-notes" className="form-control" rows={8} onChange={handleNotesChange} defaultValue={termFeePayment.notes}></textarea>
                                </Form.Group>
                                
                                {
                                    termFeePayment.receipt === null &&
                                    <Form.Group className="mb-3">
                                    <Form.Label>Receipt</Form.Label>
                                        <Form.Control 
                                        type="file"
                                        onChange={handleFileChange} 
                                        accept='image/*'
                                        />
                                    </Form.Group>
                                }

                                <Button className='edit-form-submit' variant="primary" type="submit">
                                    Save
                                </Button>
                            </Form>
                        </div>
                    </div>

                    <ReceiptComponent receipt={termFeePayment.receipt?.toString()} returnUrl='/SchoolFees' />

                </div>
                
            }

        </>
  )
}


export default EditSchoolFeePage;