import { StudentScoreRequest } from "../types/RequestModels/StudentScoreRequest";
import { StudentScore } from "../types/StudentScore";


export const StudentScoreMapper = {

    mapStudentScoreRequestToStudentScore: (studentScoreRequest: StudentScoreRequest) : StudentScore => {
        return {
            id: studentScoreRequest.id,
            achievedDate: studentScoreRequest.achievedDate,
            termId: studentScoreRequest.termId,
            studentId: studentScoreRequest.studentId,
            scoreAchieved: studentScoreRequest.scoreAchieved,
            subjectId: studentScoreRequest.subjectId,
            subject: studentScoreRequest.subject
        };
    },

    mapStudentScoreToStudentScoreRequest: (studentScore: any) : StudentScoreRequest => {
                
            return {
                id: studentScore.id,
                achievedDate: studentScore.achievedDate,
                termId: studentScore.termId,
                studentId: studentScore.studentId,
                scoreAchieved: studentScore.scoreAchieved,
                subjectId: studentScore.subjectId,
                subject: studentScore.subject,
                student: studentScore.student,
                term: studentScore.term,
                updatedBy: studentScore.updatedBy
            };
        }


};


