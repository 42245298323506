import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Class } from '../../types/Class';
import { Student } from '../../types/Student';
import { countTotalStudentsInClass } from '../../helpers/ClassHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { canEdit } from '../../helpers/UserHelper';

function SchoolFeesTable(data: any) {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

    const classes = data.data.items as Class[];
    const termId  = data.data.id;


    const hasPaidAllTermFees = (student: Student, termFee: number) => {

        const thisTermPayments = student.schoolTermFeePayments.filter(p => p.termId === termId);
        const totalPaid = thisTermPayments.reduce((total, payment) => total + payment.amountPaid, 0);

        const paid = totalPaid >= getTotalTermFee(student, termFee);

        return paid;
    }

    const getTotalPaid = (student: Student) => {

        const thisTermPayments = student.schoolTermFeePayments.filter(p => p.termId === termId);
        const totalPaid = thisTermPayments.reduce((total, payment) => total + payment.amountPaid, 0);

        return totalPaid;
    }

    const getTotalTermFee = (student: Student, termFee: number) => {

        const arrears = student.schoolFeeArrears.filter(p => p.termId === termId);

        if (arrears.length === 0) {
            return termFee;
        }

        var arrearAmount = arrears[0].amount;

        return termFee + arrearAmount;
    }

    const countTotalStudentsThatHavePaid = (students: Student[], termFee: number) => {

        const totalThatHavePaid = students.filter(s => hasPaidAllTermFees(s, termFee) === true).length;

        return totalThatHavePaid;
    }

    const countTotalStudentsThatHavePaidIn = () => {

        let totalStudentsInThatHavePaidSchool = 0;
        let totalStudentInSchool = 0;

        for (let index = 0; index < classes.length; index++) {
            const _class = classes[index];

            if(_class.name !== "PREVIOUS STUDENTS"){
                totalStudentsInThatHavePaidSchool += countTotalStudentsThatHavePaid(_class.students, _class.termFee);
                totalStudentInSchool += countTotalStudentsInClass(_class.students);
            }

            
        }

        return `${totalStudentsInThatHavePaidSchool}/${totalStudentInSchool}`;
    }

  return (
    <Accordion>
        <h1 className='centered'>{countTotalStudentsThatHavePaidIn()}</h1>
          {classes.map((item: Class) => (
              <Accordion.Item key={item.id} eventKey={item.name}>
                  <Accordion.Header>{item.name} - {countTotalStudentsThatHavePaid(item.students, item.termFee)}/{countTotalStudentsInClass(item.students)}</Accordion.Header>
                  <Accordion.Body>
                    {
                    item.students.length > 0 ? <Table striped hover responsive>
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Payments</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          {item.students.map((student: Student) => {

                            const totalPaid = getTotalPaid(student);
                            const paid = hasPaidAllTermFees(student, item.termFee);

                            return (
                                <tr key={student.id} className={`${paid ? "success" : "warning"}`}>
                                  <td>{student.name}</td>
                                  <td>
                                      {totalPaid}/{getTotalTermFee(student, item.termFee)}
                                  </td>
                                  <td>
                                      {
                                        canEdit(systemUser) &&
                                        <Link to={`/SchoolFee/${termId}/${student.id}`} className="button">
                                            <Button>
                                                Edit
                                            </Button>
                                        </Link>
                                      }
                                  </td>
                              </tr>
                              )})}
                      </tbody>
                    </Table> : <div>No termfees to show</div>}
                  </Accordion.Body>
              </Accordion.Item>
              ))}
        </Accordion>
  )
}

export default SchoolFeesTable;