import { useEffect, useState } from 'react'
import { Button, Form, Table } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getFeedingItem, postFeedingItem } from '../../functions/fetchEntities';
import { FeedingSaleExpense } from '../../types/FeedingSaleYear';
import Loading from '../Loading';
import { FeedingSaleItemRequest } from '../../types/RequestModels/FeedingSaleItemRequest';
import { FeedingSaleItemMapper } from '../../mappers/FeedingSaleItemMapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import ReceiptComponent from '../ReceiptComponent';

function EditFeedingSalePage() {


 interface FeedingSaleExpenseInput {
        id: string;
        reason: string;
        amount: string;
        receipt: string | ArrayBuffer | null;
    }

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [expenseValidated, setExpenseValidated] = useState(false);
const [feedingSale, setFeedingSale] = useState({feedingExpenses: [] as FeedingSaleExpense[] } as FeedingSaleItemRequest);
const navigate = useNavigate();

const [feedingSaleExpenseInput, setFeedingSaleExpense] = useState({receipt: null} as FeedingSaleExpenseInput);
const [showFeedingExpenseForm, setShowFeedingExpenseForm] = useState(false);

const { id } = useParams();
const parsedId = id !== undefined ? id : "";

  
  useEffect(() => {

    if (parsedId !== "new") {
        getFeedingItem(parsedId)
            .then((data) => setFeedingSale(FeedingSaleItemMapper.mapFeedingSaleItemToFeedingSaleItemRequest(data)));
    }
        
    }, [parsedId]);

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        setFeedingSale({...feedingSale, date: date});

        setHasBeenEdited(true);
    }

    const handleNumberofStudentsChange = (event: any) => {
        const numberOfStudents = event.target.value;
        setFeedingSale({...feedingSale, numberOfStudents: numberOfStudents});
        setHasBeenEdited(true);
    }

    const handleNumberofStudentsPaidChange = (event: any) => {
        const numberOfStudentsPaid = event.target.value;
        setFeedingSale({...feedingSale, numberOfStudentsPaid: numberOfStudentsPaid});
        setHasBeenEdited(true);
    }

    const handleTotalCollectedChange = (event: any) => {
        const totalCollected = event.target.value;
        setFeedingSale({...feedingSale, totalCollected: totalCollected});
        setHasBeenEdited(true);
    }

    const handleStudentsWhoHaventPaidChange = (event: any) => {
        const studentsNotPaid = event.target.value;
        setFeedingSale({...feedingSale, studentsWhoHaventPaid: studentsNotPaid});
        setHasBeenEdited(true);
    }

    const handleReasonChange = (event: any) => {
        const reason = event.target.value;
        setFeedingSaleExpense({...feedingSaleExpenseInput, reason: reason});
        setHasBeenEdited(true);
    }

    const handleAmountChange = (event: any) => {
        const amount = event.target.value;
        setFeedingSaleExpense({...feedingSaleExpenseInput, amount: amount});
        setHasBeenEdited(true);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files;
    
        if(file){
            let reader = new FileReader(); 
            reader.readAsDataURL(file[0]);
    
            reader.onload = () => {
                setFeedingSaleExpense({...feedingSaleExpenseInput, receipt: reader.result});
                setHasBeenEdited(true);
            }
        }
    }

    const handleSubmit = async (event:any) => {
        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            if (hasBeenEdited) {
                feedingSale.updatedBy = systemUser.displayName;
                await postFeedingItem(feedingSale);
                navigate('/FeedingSales', {replace: true});
            }
        }
    
        setValidated(true);
    };

    const handleFeedingExpenseSubmit = (event: any) => {

        const form = event.currentTarget;
        event.preventDefault();
    
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }else{
            var newFeedingSaleExpense = {reason: feedingSaleExpenseInput.reason, amount: parseFloat(feedingSaleExpenseInput.amount), receipt: feedingSaleExpenseInput.receipt} as FeedingSaleExpense;

            feedingSale.feedingExpenses.push(newFeedingSaleExpense);

            setFeedingSale({...feedingSale, feedingExpenses: feedingSale.feedingExpenses});
            cancelFeedingExpense();
        }
    
        setExpenseValidated(true);

    }

    const cancelFeedingExpense = () => {
        feedingSaleExpenseInput.reason = "";
        feedingSaleExpenseInput.amount = "";
        setShowFeedingExpenseForm(false);
    }


    const handleDeleteFeedingExpense = (id: string) => {
        const newFeedingExpenses = feedingSale.feedingExpenses.filter((expense) => expense.id !== id);
        setFeedingSale({...feedingSale, feedingExpenses: newFeedingExpenses});
        setHasBeenEdited(true);
    }

  
    return (
        <>
            {parsedId === "new" || feedingSale.id ?
            <div>
                <h1>Edit Feeding Sale</h1>
        
                <div className='form-background'>
                <div className='form-container'>
                    <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>

                        <Form.Group className="mb-3">
                            <Form.Label>Date</Form.Label>
                            <Form.Control 
                            type="date" 
                            placeholder="Date" 
                            value={getCalendarDate(feedingSale.date)} 
                            onChange={handleDateChange} 
                            onKeyDown={(e) => disableTyping(e)}
                            min={minDate}
                            max={maxDate}
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Number of Students</Form.Label>
                            <Form.Control 
                            id="edit-students"
                            type="number"
                            placeholder="Number of Students" 
                            onChange={handleNumberofStudentsChange} 
                            value={feedingSale.numberOfStudents} 
                            min="1"
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Number of Students Paid</Form.Label>
                            <Form.Control 
                            id="edit-students-paid"
                            type="number"
                            placeholder="Number of Students Paid" 
                            onChange={handleNumberofStudentsPaidChange} 
                            value={feedingSale.numberOfStudentsPaid} 
                            min="1"
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Total Collected</Form.Label>
                            <Form.Control 
                            id="edit-total"
                            type="number"
                            step="any" 
                            placeholder="Total Collected" 
                            onChange={handleTotalCollectedChange}
                            value={feedingSale.totalCollected} 
                            min="0.1"
                            required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Students Who Haven't Paid</Form.Label>
                            <textarea id="edit-subject" className="form-control" rows={8} onChange={handleStudentsWhoHaventPaidChange} defaultValue={feedingSale.studentsWhoHaventPaid}></textarea>
                        </Form.Group>
                        {
                            feedingSale.feedingExpenses.length > 0 &&
                            <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Reason</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    feedingSale.feedingExpenses.map((expense) => (
                                        <tr key={expense.id}>
                                            <td>{expense.reason}</td>
                                            <td>{expense.amount}</td>
                                            <td>
                                                <Button className='delete-item-button' variant="danger" onClick={() => handleDeleteFeedingExpense(expense.id)}>
                                                    X
                                                </Button>
                                            </td>       
                                        </tr>
                                    ))
                                }
                            </tbody>
                            </Table>
                        }

                        {!showFeedingExpenseForm && <Button className='edit-form-submit' variant="primary" onClick={() => setShowFeedingExpenseForm(true)}>
                            Add Feeding Expense
                        </Button>}

                        {!showFeedingExpenseForm && <Button id="save" className='edit-form-submit' variant="primary" type="submit">
                            Save
                        </Button>}
                        
                    </Form>

                    {showFeedingExpenseForm && 
                    <Form noValidate validated={expenseValidated} onSubmit={event => handleFeedingExpenseSubmit(event)}>

                        <Form.Group className="mb-3">
                            <Form.Label>Reason</Form.Label>

                            <Form.Control 
                                placeholder="Reason" 
                                type='text'
                                onChange={handleReasonChange}
                                value={feedingSaleExpenseInput.reason}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Amount</Form.Label>

                            <Form.Control 
                                placeholder="Amount"
                                type='number'
                                required
                                value={feedingSaleExpenseInput.amount}
                                min="0.1"
                                step="any"
                                onChange={handleAmountChange}
                                /> 

                        </Form.Group>

                        {
                            feedingSaleExpenseInput.receipt === null &&
                            <Form.Group className="mb-3">
                            <Form.Label>Receipt</Form.Label>
                                <Form.Control 
                                type="file"
                                onChange={handleFileChange} 
                                accept='image/*'
                                />
                            </Form.Group>
                        }

                        <Button className='edit-form-submit' variant="primary" type="submit" >
                            Enter
                        </Button>

                        <Button className='edit-form-submit' variant="primary" onClick={cancelFeedingExpense}>
                            Cancel
                        </Button>

                        </Form>}

                        <ReceiptComponent receipt={feedingSaleExpenseInput.receipt?.toString()} returnUrl='/FeedingSales' />

                </div>
                <ReceiptComponent receipt={feedingSale.receipt?.toString()} returnUrl='/FeedingSales' />

                </div>
            </div> : <Loading />}
        </>
  )
}

export default EditFeedingSalePage;