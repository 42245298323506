import { Tab, Tabs } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { getCurrentTerm } from '../../helpers/TermTimeHelper';
import SchoolGradesTable from './SchoolGradesTable';
import { SchoolScoresYear } from '../../types/SchoolScoresYear';

function SchoolGradesTabs(props:any) {

    var schoolScoresYear = props.props as SchoolScoresYear;

  return (
    <Tabs defaultActiveKey={getCurrentTerm(schoolScoresYear.termPeriods)}>
        {
            schoolScoresYear.termPeriods && schoolScoresYear.termPeriods.map((termPeriod) => (
                <Tab eventKey={termPeriod.termName} title={termPeriod.termName} key={termPeriod.termName}>
                    <h1 className='centered'>{getDisplayDate(termPeriod.startDate)} - {getDisplayDate(termPeriod.endDate)}</h1>
                    
                    <SchoolGradesTable data={termPeriod} />
                </Tab>
            ))
        }
    </Tabs>
  )
}


export default SchoolGradesTabs;