import React, { useEffect, useState } from 'react';
import { getStudentTermFeeInfo } from '../../functions/fetchEntities';
import { TermPayment } from '../../types/TermFeePayment';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';


const StudentTermFeeInfoComponent: React.FC<{termId: string, studentId: string, editMode: boolean }> = ({termId, studentId, editMode}) => {

  const [termFeePayments, setTermFeePayments] = useState([] as TermPayment[]);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [totalAmountRequired, setTotalAmountRequired] = useState(0);
  const [arrears, setArrears] = useState(0);

  const [termDisplayName, setTermDisplayName] = useState("");
  const [studentDisplayName, setStudentDisplayName] = useState("");

  const [termFee, setTermFee] = useState(0);

  const calculateTotalAmountPaid = (termPayments: TermPayment[]) => {
      let total = 0;
      termPayments.forEach((item: TermPayment) => {
          total += item.amountPaid;
      });

      return total;
  }

  useEffect(() => {

    const loadPage = async () => {

    const studentTermFeeInfo = await getStudentTermFeeInfo(termId, studentId);

    const termFeePayments = studentTermFeeInfo.termFeePayments;
    const termPeriod = studentTermFeeInfo.termPeriod;
    const student = studentTermFeeInfo.student;
    var studentArrears = studentTermFeeInfo.studentArrears;


    var sDate = new Date(termPeriod.startDate);
    var eDate = new Date(termPeriod.endDate);

    const termName = `${termPeriod.termName} (${sDate.getFullYear()}-${eDate.getFullYear()})`;

    const studentName = `${student.name} (${student.class.name})`;

    const totalPaid = calculateTotalAmountPaid(termFeePayments);

    setTermFeePayments(termFeePayments);
    setTotalAmountPaid(totalPaid);

    setTotalAmountRequired(student.class.termFee + studentArrears);
    setTermDisplayName(termName.toString());
    setTermFee(student.class.termFee);
    setStudentDisplayName(studentName);

    setArrears(studentArrears);

    }

    loadPage(); 
    }, [studentId, termId]);

  return (
  <>
    {
      <div>
          <div>
              <ul>
                  <li>{studentDisplayName} </li>
                  <li>{termDisplayName}</li>
                  <li>Term Fee: {termFee}</li>
                  {
                    arrears && 
                    <li>Arrears: {arrears}</li>
                  }
                  <li>{totalAmountPaid}/{totalAmountRequired}</li>
              </ul>

            
                {(editMode === false) && 
                    <div>
                    <div className='add-new-class-btn'>
                        <Link to={`/SchoolFeeArrear/${termId}/${studentId}`} className="button">
                            <Button className='default-btn'>
                                Edit Arrears
                            </Button>
                        </Link>    
                    </div>
                    </div>
                }

            {(totalAmountPaid < totalAmountRequired && editMode === false) && 

                <div className='add-new-class-btn'>
                    <Link to={`/SchoolFee/${termId}/${studentId}/new/edit`} className="button">
                        <Button>
                            Add Payment
                        </Button>
                    </Link>    
                </div>
                }

          </div>

          {
            editMode === false &&
            <div className='page-content'>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>Date paid</th>
                        <th>Amount</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {termFeePayments.map((payment: TermPayment) => (
                        <tr key={payment.id}>
                            <td>{getDisplayDate(payment.paymentDate)}</td>
                            <td>{payment.amountPaid}</td>
                            <td>{payment.notes}</td>
                            <td>
                                <Link to={`/SchoolFee/${termId}/${studentId}/${payment.id}/edit`} className="button">
                                    <Button>
                                        Edit
                                    </Button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </div>
          }
      </div>
    }
  </>
  );
};

export default StudentTermFeeInfoComponent;


