import React, { useEffect, useState } from 'react';
import { getStudentScoreTermInfo } from '../../functions/fetchEntities';
import { Link } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { getDisplayDate } from '../../helpers/DateHelper';
import { StudentScore } from '../../types/StudentScore';


const StudentGradeInfoComponent: React.FC<{termId: string, studentId: string, editMode: boolean }> = ({termId, studentId, editMode}) => {

  const [studentScores, setStudentScores] = useState([] as StudentScore[]);

  const [termDisplayName, setTermDisplayName] = useState("");
  const [studentDisplayName, setStudentDisplayName] = useState("");


  useEffect(() => {

    const loadPage = async () => {

    const studentScoreTermInfo = await getStudentScoreTermInfo(termId, studentId);

    const studentScores = studentScoreTermInfo.studentScores;
    const termPeriod = studentScoreTermInfo.termPeriod;
    const student = studentScoreTermInfo.student;

    var sDate = new Date(termPeriod.startDate);
    var eDate = new Date(termPeriod.endDate);

    const termName = `${termPeriod.termName} (${sDate.getFullYear()}-${eDate.getFullYear()})`;
    const studentName = `${student.name} (${student.class.name})`;

    setStudentScores(studentScores);

    setTermDisplayName(termName.toString());
    setStudentDisplayName(studentName);

    }

    loadPage(); 
    }, [studentId, termId]);

  return (
  <>
    {
      <div>
          <div>
              <ul>
                  <li>{studentDisplayName} </li>
                  <li>{termDisplayName}</li>
              </ul>

            {(editMode === false) && 

                <div className='add-new-class-btn'>
                    <Link to={`/StudentGrade/${termId}/${studentId}/new/edit`} className="button">
                        <Button>
                            Add Grade
                        </Button>
                    </Link>    
                </div>
                }

          </div>

          {
            editMode === false &&
            <div className='page-content'>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>Date Achieved</th>
                        <th>Subject</th>
                        <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                     {studentScores.map((studentScore: StudentScore) => (
                        <tr key={studentScore.id}>
                            <td>{getDisplayDate(studentScore.achievedDate)}</td>
                            <td>{studentScore.subject.name}</td>
                            <td>{studentScore.scoreAchieved}</td>
                            <td>
                                <Link to={`/StudentGrade/${termId}/${studentId}/${studentScore.id}/edit`} className="button">
                                    <Button>
                                        Edit
                                    </Button>
                                </Link>
                            </td>
                        </tr>
                    ))} 
                </tbody>
            </Table>
            </div>
          }
      </div>
    }
  </>
  );
};

export default StudentGradeInfoComponent;


