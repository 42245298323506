import { useParams } from 'react-router-dom';
// import Loading from '../Loading';
import StudentTermFeeInfoComponent from './StudentTermFeeInfoComponent';

function SchoolFeePayment() {

const { termId, studentId } = useParams();
const parsedTermId = termId !== undefined ? termId : "";
const parsedStudentId = studentId !== undefined ? studentId : "";


    return (
        <>
            {
                <div>
                    <StudentTermFeeInfoComponent termId={parsedTermId} studentId={parsedStudentId} editMode={false} /> 
                </div>
            }
        </>
    )
}

export default SchoolFeePayment;