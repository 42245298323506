import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { getSchoolFeeArrear, postSchoolFeeArrear } from '../../functions/fetchEntities';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import StudentTermFeeInfoComponent from './StudentTermFeeInfoComponent';
import { SchoolFeeArrearRequest } from '../../types/RequestModels/SchoolFeeArrearRequest';


function EditSchoolFeeArrearPage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const { termId, studentId } = useParams();
const parsedTermId = termId !== undefined ? termId : "";
const parsedStudentId = studentId !== undefined ? studentId : "";

//TODO: make sure IDs is not null
const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [schoolFeeArrear, setSchoolFeeArrear] = useState({
    id: "",
    termId: parsedTermId,
    studentId: parsedStudentId,
    amount: 0,
}  as SchoolFeeArrearRequest);


const navigate = useNavigate();


const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseFloat(event.target.value);
    setSchoolFeeArrear({...schoolFeeArrear, amount: amount});
    setHasBeenEdited(true);
}

const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const notes = event.target.value;
    setSchoolFeeArrear({...schoolFeeArrear, notes: notes});
    setHasBeenEdited(true);
}

const handleSubmit = async (event:any) => {

    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        if(hasBeenEdited){
            schoolFeeArrear.updatedBy = systemUser.displayName;
            postSchoolFeeArrear(schoolFeeArrear)
                .then(() => navigate(`/SchoolFee/${parsedTermId}/${parsedStudentId}`));
        }
    }

    setValidated(true);
  };

  useEffect(() => {

    const loadPage = async () => {

        const schoolFeeArrear = await getSchoolFeeArrear(parsedTermId, parsedStudentId);
    
        setSchoolFeeArrear(schoolFeeArrear);
    }

    loadPage(); 

    // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                <div>
                    <StudentTermFeeInfoComponent termId={parsedTermId} studentId={parsedStudentId} editMode={true} /> 

                    <div className='form-background'>
                        <div className='form-container'>
                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)} >

                                <Form.Group className="mb-3" controlId="formPaid">
                                    <Form.Label>Term Arrears</Form.Label>
                                    <Form.Control 
                                    type="number"
                                    step="any" 
                                    placeholder="Term Arrears" 
                                    onChange={handleAmountChange} 
                                    value={schoolFeeArrear.amount} 
                                    min="0.1"
                                    required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formNotes">
                                    <Form.Label>Notes</Form.Label>
                                    <textarea className="form-control" id="edit-notes" rows={3} onChange={handleNotesChange} value={schoolFeeArrear.notes} />
                                </Form.Group>

                                <Button className='edit-form-submit' variant="primary" type="submit">
                                    Save
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
                
            }

        </>
  )
}

export default EditSchoolFeeArrearPage;