import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import EditAccountTransaction from '../AccountTransaction/EditAccountTransaction';
import BookSalesPage from '../BookSales/BookSalesPage';
import EditBookSalesPage from '../BookSales/EditBookSalesPage';
import Classes from '../Class/Classes';
import EditClass from '../Class/EditClass';
import ClassFeesPage from '../ExtraClasses/ClassFeesPage';
import EditClassFeesPage from '../ExtraClasses/EditClassFeesPage';
import EditExpense from '../Expense/EditExpense';
import EditExpenseType from '../Expense/EditExpenseType';
import Expenses from '../Expense/Expenses';
import EditFeedingSalePage from '../FeedingSales/EditFeedingSalePage';
import FeedingSalesPage from '../FeedingSales/FeedingSalesPage';
import Home from '../Home';
import EditInventory from '../Inventory/EditInventory';
import EditInventoryType from '../Inventory/EditInventoryType';
import Inventory from '../Inventory/Inventory';
import EditLibraryBook from '../Library/EditLibraryBook';
import EditLibraryBookRental from '../Library/EditLibraryBookRental';
import Library from '../Library/Library';
import EditStudent from '../Student/EditStudent';
import Students from '../Student/Students';
import EditStaff from '../Staff/EditStaff';
import Staff from '../Staff/Staff';
import EditUniformSalesPage from '../UniformSales/EditUniformSalesPage';
import UniformSalesPage from '../UniformSales/UniformSalesPage';
import Users from '../Users/Users';
import NavContainer from './NavLinks';
import SchoolFees from '../SchoolFees/SchoolFeesPage';
import TermYears from '../Terms/TermYears';
import EditTermYears from '../Terms/EditTermYears';
import EditSchoolFeePage from '../SchoolFees/EditSchoolFeePage';
import SchoolFeePayment from '../SchoolFees/SchoolFeePayment';
import NewAdmission from '../NewAdmission/NewAdmission';
import EditMultipleStudent from '../Student/EditMultipleStudent';
import EditSystemUser from '../Users/EditSystemUser';
import AuditLogs from '../AuditLogs/AuditLogs';
import PandCUniformSalesPage from '../UniformSales/PandCUniformSales/PandCUniformSalesPage';
import EditPandCUniformSalesPage from '../UniformSales/PandCUniformSales/EditPandCUniformSalesPage';
import ReportPage from '../Report/ReportPage';
import ExamFeePayment from '../ExamFees/ExamFeePayment';
import EditExamFeePage from '../ExamFees/EditExamFeePayment';
import ExamFeesPage from '../ExamFees/ExamFeesPage';
import AccountTransactionsPage from '../AccountTransaction/AccountTransactionsPage';
import HealthCheck from '../HealthCheck';
import InventorySalesPage from '../Inventory/InventorySalesPage';
import EditInventorySalesPage from '../Inventory/EditInventorySalesPage';
import EditSchoolFeeArrearPage from '../SchoolFees/EditSchoolFeeArrearPage';
import Subjects from '../Subjects/Subjects';
import EditSubject from '../Subjects/EditSubject';
import SchoolGradesPage from '../Grades/SchoolGradesPage';
import StudentGrades from '../Grades/StudentGrades';
import EditStudentGradePage from '../Grades/EditStudentGradePage';

export default class NavbarComp extends Component {



  render() {
    return (
      <Router>
        <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <NavContainer />
        </Navbar>
        </div>
        <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/NewAdmission" element={<NewAdmission />} />
          <Route path="/SchoolFees" element={<SchoolFees />} />
          <Route path="/SchoolFee/:termId/:studentId" element={<SchoolFeePayment />} />
          <Route path="/SchoolFee/:termId/:studentId/:paymentId/edit" element={<EditSchoolFeePage />} />
          <Route path="/SchoolFeeArrear/:termId/:studentId" element={<EditSchoolFeeArrearPage />} />
          <Route path="/ExamFees" element={<ExamFeesPage />} />
          <Route path="/ExamFee/:yearId/:termId/:studentId" element={<ExamFeePayment />} />
          <Route path="/ExamFee/:yearId/:termId/:studentId/:paymentId/edit" element={<EditExamFeePage />} />
          <Route path="/ExtraClasses" element={<ClassFeesPage />} />
          <Route path="/ExtraClassFee/:id" element={<EditClassFeesPage />} />
          <Route path="/Classes" element={<Classes />} />
          <Route path="/Class/:id" element={<EditClass />} />
          <Route path="/Students" element={<Students />} />
          <Route path="/Student/:id" element={<EditStudent />} />
          <Route path="/EditMultipleStudents" element={<EditMultipleStudent />} />
          <Route path="/Staff" element={<Staff />} />
          <Route path="/Staff/:id" element={<EditStaff />} />
          <Route path="/AccountTransactions" element={<AccountTransactionsPage />} />
          <Route path="/AccountTransaction/:id" element={<EditAccountTransaction />} />
          <Route path="/Inventory" element={<Inventory />} />
          <Route path="/InventoryType/:id" element={<EditInventoryType />} />
          <Route path="/InventorySales" element={<InventorySalesPage />} />
          <Route path="/InventorySale/:id" element={<EditInventorySalesPage />} />
          <Route path="/Inventory/:id" element={<EditInventory />} />
          <Route path="/Expenses" element={<Expenses />} />
          <Route path="/ExpenseType/:id" element={<EditExpenseType />} />
          <Route path="/Expense/:id" element={<EditExpense />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/BookSales" element={<BookSalesPage />} />
          <Route path="/BookSale/:id" element={<EditBookSalesPage />} />
          <Route path="/UniformSales" element={<UniformSalesPage />} />
          <Route path="/UniformSale/:id" element={<EditUniformSalesPage />} />
          <Route path="/PandCUniformSales" element={<PandCUniformSalesPage />} />
          <Route path="/PandCUniformSale/:id" element={<EditPandCUniformSalesPage />} />
          <Route path="/FeedingSales" element={<FeedingSalesPage />} />
          <Route path="/FeedingSale/:id" element={<EditFeedingSalePage />} />
          <Route path="/Library" element={<Library />} />
          <Route path="/LibraryBook/:id" element={<EditLibraryBook />} />
          <Route path="/LibraryBookRental/:bookId/:id" element={<EditLibraryBookRental />} />
          <Route path="/TermYears" element={<TermYears />} />
          <Route path="/TermYear/:id" element={<EditTermYears />} />
          <Route path="/Subjects" element={<Subjects />} />
          <Route path="/Subject/:id" element={<EditSubject />} />
          <Route path="/SystemUser/:id" element={<EditSystemUser />} />
          <Route path="/SystemHistory" element={<AuditLogs />} />
          <Route path="/Report" element={<ReportPage />} />
          <Route path="/Health" element={<HealthCheck />} />
          <Route path="/Grades" element={<SchoolGradesPage />} />
          <Route path="/StudentGrades/:termId/:studentId" element={<StudentGrades />} />
          <Route path="/StudentGrade/:termId/:studentId/:gradeId/edit" element={<EditStudentGradePage />} />
        </Routes>
        </div>
      </Router>
    )
  }
}
