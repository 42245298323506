import React from 'react';
import { deleteReceipt } from '../functions/fetchEntities';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import { IsSuperUser } from '../helpers/UserHelper';

const ReceiptComponent: React.FC<{receipt: string | undefined, returnUrl: string }> = ({receipt, returnUrl}) => {

  const state = useSelector((state: RootState) => state.systemUser);
  const systemUser = state.systemUser;

  const navigate = useNavigate();

  const _deleteReceipt = (id:string) => {
    deleteReceipt(id);
    navigate(returnUrl, {replace: true});
  }

  return (
  <>
    {
      (receipt !== null && receipt !== undefined) &&
      <div className='receipt-container'>
          {
            (!receipt.includes('data') && IsSuperUser(systemUser)) && 
            <div className='button-container' onClick={e => _deleteReceipt(receipt)}>
              <button>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
              </button>
            </div>
          }
          <img className='receipt-img' src={receipt} alt={receipt} />
      </div>
    }
  </>
  );
};

export default ReceiptComponent;


