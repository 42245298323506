import { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { deleteGrade, getStudentScoreTermInfo, getSubjects, postStudentScore } from '../../functions/fetchEntities';
import { disableTyping, getCalendarDate, maxDate, minDate } from '../../helpers/DateHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import StudentGradeInfoComponent from './StudentGradeInfoComponent';
import { Subject } from '../../types/Subject';
import { StudentScoreRequest } from '../../types/RequestModels/StudentScoreRequest';
import { StudentScore } from '../../types/StudentScore';
import { StudentScoreMapper } from '../../mappers/StudentScoreMapper';
import { IsSuperUser } from '../../helpers/UserHelper';


function EditStudentGradePage() {

const state = useSelector((state: RootState) => state.systemUser);
const systemUser = state.systemUser;

const { termId, studentId, gradeId } = useParams();
const parsedTermId = termId !== undefined ? termId : "";
const parsedStudentId = studentId !== undefined ? studentId : "";
const parsedGradeId = gradeId !== undefined || gradeId !== "new" ? gradeId : "";

//TODO: make sure IDs is not null
const [hasBeenEdited, setHasBeenEdited] = useState(false);
const [validated, setValidated] = useState(false);
const [studentScore, setStudentScore] = useState({
    id: "",
    achievedDate: new Date(),
    termId: parsedTermId,
    studentId: parsedStudentId,
    scoreAchieved: 0,
}  as StudentScoreRequest);

const [subjects, setSubjects] = useState<Subject[]>([]);


const navigate = useNavigate();

const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    setStudentScore({...studentScore, achievedDate: date});
    setHasBeenEdited(true);
}

const handleSchoreAchievedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const scoreAchieved = parseFloat(event.target.value);
    setStudentScore({...studentScore, scoreAchieved: scoreAchieved});
    setHasBeenEdited(true);
}

const handleSubjectChange = (event: any) => {
    const subjectId = event.target.value;

    setStudentScore({...studentScore, subjectId: subjectId});
    setHasBeenEdited(true);
}

const handleDelete = async () => {
    if (window.confirm(`Are you sure you want to delete ${studentScore.subject.name}`)) {

        await deleteGrade(studentScore.id);
  
        alert("Grade deleted");
        navigate('/Grades', {replace: true});
      }
}

const handleSubmit = async (event:any) => {

    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }else{
        if(hasBeenEdited){
            studentScore.updatedBy = systemUser.displayName;
            postStudentScore(studentScore)
                .then(() => navigate(`/StudentGrades/${parsedTermId}/${parsedStudentId}`));
        }
    }

    setValidated(true);
  };

  
  useEffect(() => {

        const loadPage = async () => {

        const studentScoreTermInfo = await getStudentScoreTermInfo(parsedTermId, parsedStudentId);

        const grade = studentScoreTermInfo.studentScores.find((item: StudentScore) => item.id === parsedGradeId);

        if(grade !== undefined){
            setStudentScore(StudentScoreMapper.mapStudentScoreToStudentScoreRequest(grade));
        }

        getSubjects()
            .then(data => setSubjects(data))
    }

     loadPage(); 
    }, [parsedGradeId, parsedStudentId, parsedTermId]);

    
    return (
        <>
            {
                <div>
                    <StudentGradeInfoComponent termId={parsedTermId} studentId={parsedStudentId} editMode={true} /> 

                    <div className='form-background'>
                        <div className='form-container'>
                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)} >
                                <Form.Group className="mb-3" controlId="formDate">
                                    <Form.Label>Date Achieved</Form.Label>
                                    <Form.Control 
                                    type="date" 
                                    placeholder="Date Achieved" 
                                    value={getCalendarDate(studentScore.achievedDate)} 
                                    onChange={handleDateChange} 
                                    onKeyDown={(e) => disableTyping(e)}
                                    min={minDate}
                                    max={maxDate}
                                    required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Subject</Form.Label>
                                    <select className="form-select" aria-label="Subject" onChange={handleSubjectChange} required>
                                        <option value="">Select a subject</option>
                                        {subjects.map((subject) => (
                                            <option key={subject.id} 
                                            value={subject.id} 
                                            selected={studentScore.subjectId === subject.id}>
                                                {subject.name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPaid">
                                    <Form.Label>Score Obtained</Form.Label>
                                    <Form.Control 
                                    type="number"
                                    step="any" 
                                    placeholder="Score Obtained" 
                                    onChange={handleSchoreAchievedChange} 
                                    value={studentScore.scoreAchieved} 
                                    min="0.1"
                                    required
                                    />
                                </Form.Group>

                                <Button className='edit-form-submit' variant="primary" type="submit">
                                    Save
                                </Button>

                                {
                                    (parsedGradeId !== "new" && IsSuperUser(systemUser)) && (
                                        <Button className='edit-form-submit' variant="danger" onClick={handleDelete} >
                                            Delete
                                        </Button>
                                    )
                                }
                            </Form>
                        </div>
                    </div>
                </div>
                
            }

        </>
  )
}

export default EditStudentGradePage;