import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { getStudentScores } from '../../functions/fetchEntities';
import Loading from '../Loading';
import SchoolGradesTabs from './SchoolGradesTabs';
import { SchoolScoresYear } from '../../types/SchoolScoresYear';

function SchoolGradesPage() {

const [schoolScores, setSchoolScoresYears] = useState([] as SchoolScoresYear[]);
const [selectedYear, setSelectedYear] = useState({} as SchoolScoresYear);

  useEffect(() => {

    const getProperties = async () => {
        const schoolScores = await getStudentScores();

        setSchoolScoresYears(schoolScores);
        setSelectedYear(schoolScores[0]);
    }

    getProperties();

    }, []);


    const handleTermYearChange = (event: React.MouseEvent<HTMLElement>) => {
        const yearPeriod = (event.target as HTMLElement).innerText;

        const selectedYear = schoolScores.find((schoolScore) => schoolScore.periodName === yearPeriod);
        setSelectedYear(selectedYear as SchoolScoresYear);
    }
  
    return (
        <>
            {
            schoolScores ?
            <div>
                <div className="top-buttons">
                    <div className='select-year-btn'>
                        <Dropdown className="navitem">
                            <Dropdown.Toggle variant="success" id="selectYear">
                                {selectedYear.periodName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    schoolScores.map((schoolScore) => (
                                        <Dropdown.Item onClick={handleTermYearChange} key={schoolScore.periodName}>{schoolScore.periodName} </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                {selectedYear.termPeriods && <SchoolGradesTabs props={selectedYear} />}
            </div> : <Loading />}
        </>
  )
}

export default SchoolGradesPage;