import React, { useEffect, useState } from 'react'
import { Accordion, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getEnvironmentVariable, getInventory, getInventoryTypes } from '../../functions/fetchEntities';
import { InventoryType } from '../../types/InventoryType';
import { InventoryItem } from '../../types/InventoryItem';
import Loading from '../Loading';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { createButtonTestId, createRowTestId } from '../../helpers/StringHelper';
import { getBooleanString } from '../../helpers/BooleanHelper';
import { canEdit, IsSuperUser } from '../../helpers/UserHelper';

function Inventory() {

    const state = useSelector((state: RootState) => state.systemUser);
    const systemUser = state.systemUser;

  const [inventoryTypes, setInventoryTypes] = useState<InventoryType[]>([]);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [uniformTypeID, setUniformTypeId] = useState<string>("");



  useEffect(() => {
    // fetch data
    getInventoryTypes().then((data) => {
      setInventoryTypes(data);
    })

    getInventory().then((data) => {
        setInventoryItems(data);
        }
    )

    getEnvironmentVariable("UNIFORM_TYPE_ID").then((data) => setUniformTypeId(data));

  }, [])

  return (
  <>
     {inventoryItems.length > 0 ?
     <div>
        {
            canEdit(systemUser) && 
            <div className='add-new-class-btn'>
                <Link className="navitem" to="/InventoryType/new">
                    <Button variant="primary" className="mb-3">
                        Add Inventory Type
                    </Button>
                </Link>
            </div>
        }
          
        <div className='page-content'>
            <Table striped hover responsive>
            <thead>
                <tr>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {inventoryTypes.map((item: InventoryType) => (
                    <tr id={createRowTestId(item.name)} className='inventory-type-table-row' key={item.id}>
                        <td className='item-name'>{item.name}</td>
                        <td>
                            {
                                !item.isCritical ? <Link to={`/InventoryType/${item.id}`} className="button">
                                {
                                    canEdit(systemUser) &&
                                    <Button id={createButtonTestId(item.name)}>
                                        Edit
                                    </Button>
                                }
                            </Link>
                            : null
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
            </Table>
        </div>

        {
            canEdit(systemUser) &&
            <div className='add-new-class-btn'>
                <Link className="navitem" to="/Inventory/new">
                    <Button variant="primary" className="mb-3">
                        Add Inventory
                    </Button>
                </Link>
            </div>
        }

        <Accordion>
            {inventoryTypes.map((itemType: InventoryType) => {
                
                const relatedItems = inventoryItems.filter(i => i.inventoryTypeId === itemType.id);
                
                return (
                    <Accordion.Item eventKey={itemType.name}>
                         <Accordion.Header className={`${itemType.name}-header`}>{itemType.name}</Accordion.Header>
                         <Accordion.Body>
                         <Table striped hover responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    {IsSuperUser(systemUser) ? <th>Cost Per Unit</th> : null}
                                    {itemType.id !== uniformTypeID ? <th>Selling Price</th> : null}
                                    {itemType.id !== uniformTypeID ? <th>For Sale</th> : null}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {relatedItems.map((item: InventoryItem) => (
                                    <tr id={createRowTestId(item.name)} key={item.id}>
                                        <td className='item-name'>{item.name}</td>
                                        <td className='item-quantity'>{item.quantity}</td>
                                        {IsSuperUser(systemUser) ? <td>{item.costPerUnit}</td> : null}
                                        {
                                            itemType.id !== uniformTypeID ? <td>{item.sellingPrice}</td> : null
                                        }
                                        {
                                            itemType.id !== uniformTypeID ? <td className='item-for-sale'>{getBooleanString(item.isSellable)}</td> : null
                                        }
                                        <td>
                                            {
                                                canEdit(systemUser) &&
                                                <Link to={`/Inventory/${item.id}`} className="button">
                                                    <Button id={createButtonTestId(item.name)}>
                                                        Edit
                                                    </Button>
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                     
                         </Table> 
                         </Accordion.Body>
                    </Accordion.Item>
                )
            })}
        </Accordion>
    </div> : <Loading />}
  </>
  )
}

export default Inventory;