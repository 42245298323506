import { useEffect, useState } from 'react'
import { Accordion, Table } from 'react-bootstrap';
import { getNewAdmissions } from '../../functions/fetchEntities';
import { NewAdmissionClass, NewAdmissionStudent } from '../../types/Class';
import Loading from '../Loading';
import { getYesNoString } from '../../helpers/BooleanHelper';

function NewAdmission() {

  const [classes, setClasses] = useState<NewAdmissionClass[]>([]);

  useEffect(() => {

    // fetch data
    const getProperties = async () => {
        const newAdmissions = await getNewAdmissions();
        setClasses(newAdmissions)
    }

    getProperties();
  }, [])

  return (
    <>
        {classes.length > 0 ?

        <Accordion defaultActiveKey={classes.map(c => c.name)} alwaysOpen>
          {classes.map((item: NewAdmissionClass) => (
              <Accordion.Item key={item.id} eventKey={item.name}>
                  <Accordion.Header>{item.name}</Accordion.Header>
                  <Accordion.Body>
                    {item.students.length > 0 ? <Table striped hover responsive>
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Has paid for first term</th>
                              <th>Has paid for all uniform</th>
                              <th>Has paid new admission form</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                          item.students.map((student: NewAdmissionStudent) => {

                            return (
                                <tr key={student.name}>
                                  <td>{student.name}</td>
                                  <td>{getYesNoString(student.hasPaidFirstTerm)}</td>
                                  <td>{getYesNoString(student.hasPaidForAllUniformTypes)}</td>
                                  <td>
                                    {
                                      getYesNoString(student.hasPaidNewAdmissionFormFee)
                                    }
                                  </td>
                                </tr>
                                )
                          })}
                      </tbody>
                    </Table> : <div>No students in this class owe new admission fees</div>}
                  </Accordion.Body>
              </Accordion.Item>
              ))}
        </Accordion> : <Loading /> }

    </>
  )
}

export default NewAdmission;